import React from "react";

import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import numeral from "numeral";
import Checkbox from "@material-ui/core/Checkbox";

type FormatterProps = {
  row: any;
  column: any;
  style?: any;
  onDoubleClickHandler?: () => void;
  onClickHandler?: () => void;
  includeTime?: boolean;
  actions?: any;
};

export enum FormatterTypes {
  Text = 0,
  Date = 1,
  Number = 2,
  Number2Decimal = 3,
  Number3Decimal = 4,
  Recon = 5,
}

export const DateFormatter: React.FC<FormatterProps> = ({ row, column, style, includeTime = false }) => {
  const data = row[column.key];
  const errorStyle = errorEdiComparisonHandler(row, column);
  try {
    if (data) {
      const filterDate = includeTime ? format(parseISO(data), "dd MMM yyyy HH:mm") : format(parseISO(data), "dd MMM yyyy");
      return <div style={{ height: "100%", ...style, ...(errorStyle || {}) }}>{filterDate}</div>;
    }
  } catch (error) {}
  return <div></div>;
};

export const TextFormatter: React.FC<FormatterProps> = ({ row, column, style }) => {
  const data = row[column.key];
  const errorStyle = errorEdiComparisonHandler(row, column);
  return <div style={{ height: "100%", ...style, ...(errorStyle || {}) }}>{data}</div>;
};

export const FormatterNumber: React.FC<FormatterProps> = ({ row, column, style }) => {
  const data = row[column.key];
  const errorStyle = errorEdiComparisonHandler(row, column);
  return (
    <div id="formatterNumber" style={{ textAlign: "right", height: "100%", ...(style || {}), ...(errorStyle || {}) }}>
      {data || data == 0 ? numeral(data).format("0,0") : null}
    </div>
  );
};

export const FormatterNumber2Decimals: React.FC<FormatterProps> = ({ row, column, style }) => {
  const data = row[column.key];
  const errorStyle = errorEdiComparisonHandler(row, column);
  return (
    <div id="formatter2decimal" style={{ textAlign: "right", ...(style || {}), ...(errorStyle || {}) }}>
      {data || data == 0 ? numeral(data).format("0,0.00") : null}
    </div>
  );
};

export const FormatterNumber2DecimalsNegative: React.FC<FormatterProps> = ({ row, column, style }) => {
  const data = row[column.key];
  return <div style={{ textAlign: "right", height: "100%", ...(style || {}) }}>- {data ? numeral(data).format("0,0.00") : 0}</div>;
};

export const FormatterNumber3Decimals: React.FC<FormatterProps> = ({ row, column, style }) => {
  const data = row[column.key];
  return <div style={{ textAlign: "right", height: "100%", ...(style || {}) }}>{numeral(data).format("0,0.000")}</div>;
};

export const FormatterBoolean: React.FC<FormatterProps> = ({ row, column, style }) => {
  const data = row[column.key];
  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
      <Checkbox color="default" checked={data && data == 1 ? true : false} inputProps={{ "aria-label": "primary checkbox" }} />
    </div>
  );
};

export const FormatterRecon: React.FC<FormatterProps> = ({ row, column, style }) => {
  const data = row[column.key];
  let reconvalue = "";
  if (data) {
    reconvalue = "000000" + data;
    reconvalue = reconvalue.slice(reconvalue.length - 6);
    reconvalue = "PR" + reconvalue;
  }
  return <div style={{ textAlign: "left" }}>{reconvalue}</div>;
};

export const FormatterArrivalQuality: React.FC<FormatterProps> = ({ row, column, style }) => {
  const data = row[column.key];
  let backcolor = "white";
  if (data == 1) backcolor = "red";
  if (data == 2) backcolor = "orange";
  if (data == 3) backcolor = "green";
  return <div style={{ textAlign: "center", backgroundColor: backcolor, fontWeight: "bold" }}>{data}</div>;
};

export const CenterTextFormatter: React.FC<FormatterProps> = ({ row, column, style }) => {
  const data = row[column.key];
  return <div style={{ height: "100%", textAlign: "center", ...style }}>{data}</div>;
};

export const RightAlignColumnHeader = ({ column }) => <span style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>{column.name}</span>;

export const CenterAlignColumnHeader = ({ column }) => <span style={{ width: "100%", display: "flex", justifyContent: "center" }}>{column.name}</span>;

const errorEdiComparisonHandler = (row: any, column: any) => {
  try {
    const isError = (row.edicomparisonerror || "").split(", ");

    if (isError != "") {
      if (isError.includes("Missing")) {
        return { color: "white", fontWeight: "normal" };
      } else if (isError.find((col) => col == column.key)) {
        return { color: "red", fontWeight: "bold" };
      }
    }
  } catch (error) {
    console.log("error", error);
  }
  return {};
};
