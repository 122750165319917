import React, { useEffect, useState, forwardRef, FC, useContext, useMemo } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Settings from "@material-ui/icons/Settings";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import LinearProgress from "@material-ui/core/LinearProgress";

import IconClose from "@material-ui/icons/Close";
import IconCheck from "@material-ui/icons/Check";

import { Field, Form } from "react-final-form";
import { Select, TextField } from "final-form-material-ui";
import ReactDatePicker from "react-datepicker";
import isValid from "date-fns/isValid";
import numeral from "numeral";

import { getConsigneeReadyForCombo } from "../../lib/api/consignee";
import { getClearingAgentReadyForCombo } from "../../lib/api/clearingagent";
import { getTermsAllSortedMappedforCombo } from "../../lib/api/terms";
import { getUsersAllSortedMappedforCombo } from "../../lib/api/users";
import { getSellingTermsReadyForCombo } from "../../lib/api/sellingterms";
import { getSellingTermsFormatted } from "../../lib/api/dealtype";
import { getActiveCurrencies } from "../../lib/api/currency";
import Confirmation from "../../lib/components/confirmation";
import { bankingDetailsCombo } from "../../lib/api/companydetailsbanking";
import { clientsfinance, clientsfinanceByClientID } from "../../lib/api/clientsfinance";
import { GenerateErrorMessage } from "../../lib/helpers/string_methods";
import { SnackContext } from "../../lib/context/SnackContext";
import ConsigneeEdit from "./clientseditconsignee";
import Consignee from "../consignee/consignee";
import Terms from "../terms/terms";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(2),
    },
    tableRowFieldTitle: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
      textAlign: "right",
      minWidth: "175px",
    },
    tableRowFieldData: {
      textAlign: "left",
      width: "400px",
    },
    tableRowFieldDataText: {
      paddingTop: theme.spacing(0.5),
      textAlign: "left",
      width: "400px",
    },
    tableCellLabel: {
      width: "150px",
      borderBottom: "none",
      height: "50px",
      textAlign: "right",
      paddingTop: "5px",
    },
    tableCellLabelValue: {
      width: "350px",
      borderBottom: "none",
      height: "50px",
      textAlign: "left",
      paddingTop: "5px",
    },
    tableCellDetailDate: {
      marginTop: "-5px",
      width: "350px",
      borderBottom: "none",
      height: "50px",
    },
    inline: {
      float: "right",
      marginTop: "-35px",
    },
    table: {
      float: "left",
    },
    actionButtons: {
      display: "flex",
      justifyContent: "flex-end",
      gap: theme.spacing(1),
      flexDirection: "row",
      position: "fixed",
      right: "50px",
    },
  });

type ClientEditFinFormProps = {
  clientId: number | undefined;
  handleClose(): void;
  toggleDirty(isDirty: boolean): void;
} & WithStyles<typeof styles>;

const ClientEditFinFormUnstyled: FC<ClientEditFinFormProps> = ({ classes, clientId, handleClose, toggleDirty }) => {
  const { updateSnack } = useContext(SnackContext);

  const [loading, setLoading] = useState(true);

  const [consignees, setConsignees] = useState([]);
  const [clearingAgents, setClearingAgents] = useState([]);
  const [terms, setTerms] = useState();
  const [usersData, setUsersData] = useState([]);
  const [sellingterms, setSellingterms] = useState([]);
  const [dealtypes, setDealtypes] = useState([]);
  const [activeCurrencies, setActiveCurrencies] = useState([]);
  const [editingConsignees, setEditingConsignees] = useState(false);
  const [editingTerms, setEditingTerms] = useState(false);
  const [forceReloadConsignees, setForceReloadConsignees] = useState(false);
  const [forceReloadTerms, setForceReloadTerms] = useState(false);
  const [bankingDetails, setBankingDetails] = useState([]);

  const [financeData, setFinanceData] = useState({});

  useEffect(() => {
    loadTerms();
  }, [forceReloadTerms]);

  const loadTerms = async () => {
    const result = await getTermsAllSortedMappedforCombo(0);
    setTerms(result);
  };

  const loadData = async () => {
    setLoading(true);
    try {
      const [consignees, users, sellingterms, dealtypes, activeCurrencies, finance, bankingDetails, _] = await Promise.all([
        getConsigneeReadyForCombo(clientId, 0),
        getUsersAllSortedMappedforCombo(true),
        getSellingTermsReadyForCombo(),
        getSellingTermsFormatted(),
        getActiveCurrencies(),
        clientsfinanceByClientID(clientId),
        bankingDetailsCombo(),
        loadTerms(),
      ]);
      setConsignees(consignees);
      setUsersData(users);
      setSellingterms(sellingterms);
      setDealtypes(dealtypes);
      setActiveCurrencies(activeCurrencies);
      setBankingDetails(bankingDetails.data);

      const financeData = (finance.data || [])[0];
      setFinanceData({
        ...financeData,
        credit_limit_exp: financeData ? new Date(financeData.credit_limit_exp) : new Date(),
        credit_limit: numeral(financeData.credit_limit).format("0,0.00"),
      });
    } catch (error) {
      const err = GenerateErrorMessage(error, "Failed to fetch data");
      updateSnack({ color: "red", message: err, show: true });
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [clientId]);

  const handleCloseConsigneeMaintenance = () => {
    setEditingConsignees(false);
    setForceReloadConsignees(!forceReloadConsignees);
  };

  const handleCloseTermsMaintenance = () => {
    setEditingTerms(false);
    setForceReloadTerms(!forceReloadTerms);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const data = {
        data: {
          clients_id: clientId,
          consignee_id: values.consignee_id,
          clearingagent_id: values.clearingagent_id,
          terms_id: values.terms_id,
          trader_id: values.trader_id,
          sellingterms: values.sellingterms,
          dealtype: values.dealtype,
          currency_id: values.currency_id,
          statement_account_id: values.statement_account_id,
          credit_limit: +(values.credit_limit || "").toString().replaceAll(",", ""),
          credit_limit_exp: values.credit_limit_exp || new Date(),
        },
      };
      if (values.id) {
        await clientsfinance.update(values.id, data);
      } else {
        await clientsfinance.create(data);
      }
      updateSnack({ color: "green", message: "Successfully submitted data", show: true });
    } catch (error) {
      const err = GenerateErrorMessage(error, "Failed to create/update finance details");
      updateSnack({ color: "red", message: err, show: true });
    }
    await loadData();
    setLoading(false);
  };

  if (loading) return <LinearProgress color="secondary" />;

  return (
    <div className={classes.root}>
      <Form
        initialValues={{ ...financeData }}
        onSubmit={handleSubmit}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <div className={classes.actionButtons}>
              <Button color="secondary" variant="outlined" onClick={handleClose} type="button">
                <IconClose />
              </Button>
              <Button disabled={!form.getState().dirty || clientId == 0} type="submit" color="primary" variant="outlined">
                <IconCheck />
              </Button>
            </div>
            {clientId == 0 && form.getState().dirty && <p style={{ color: "red", lineHeight: "10px" }}>Please ensure the CLIENT form is completed before continuing.</p>}
            <div style={{ display: "flex", flexDirection: "row", gap: "40px" }}>
              <FormDetail
                form={form}
                terms={terms}
                classes={classes}
                clientId={clientId}
                usersData={usersData}
                dealtypes={dealtypes}
                consignees={consignees}
                sellingterms={sellingterms}
                bankingDetails={bankingDetails}
                clearingAgents={clearingAgents}
                activeCurrencies={activeCurrencies}
                forceReloadConsignees={forceReloadConsignees}
                toggleDirty={toggleDirty}
                setConsignees={setConsignees}
                setEditingTerms={setEditingTerms}
                setClearingAgents={setClearingAgents}
                setEditingConsignees={setEditingConsignees}
              />
              <ConsigneeEdit clientid={clientId} TopDown forceReload={forceReloadConsignees} dataChangedEvent={setForceReloadConsignees} />
            </div>
          </form>
        )}
      />
      <Confirmation
        fullscreen={false}
        isOpen={editingConsignees}
        handleClose={handleCloseConsigneeMaintenance}
        handleConfirm={() => {}}
        title={"Clients > Finance > Consignee Maintenance"}
        body={undefined}
      >
        <div>
          <Consignee forceHeight={500} />
        </div>
        <div className={classes.inline} style={{ position: "absolute", right: "0", top: "0", marginTop: "-50px" }}>
          <Tooltip title="Close Consignee Maintenance" placement="bottom">
            <Button variant="text" style={{ color: "#FFFFFF" }} color="primary" onClick={handleCloseConsigneeMaintenance}>
              <IconClose />
            </Button>
          </Tooltip>
        </div>
      </Confirmation>
      <Confirmation
        fullscreen={false}
        isOpen={editingTerms}
        handleClose={handleCloseTermsMaintenance}
        handleConfirm={() => {}}
        title={"Clients > Finance > Terms Maintenance"}
        body={undefined}
      >
        <div style={{ width: "1000px" }}>
          <Terms termstype={0} forceHeight={500} />
        </div>
        <div className={classes.inline} style={{ position: "absolute", right: "0", top: "0", marginTop: "-50px" }}>
          <Tooltip title="Close Terms Maintenance" placement="bottom">
            <Button variant="text" style={{ color: "#FFFFFF" }} color="primary" onClick={handleCloseTermsMaintenance}>
              <IconClose />
            </Button>
          </Tooltip>
        </div>
      </Confirmation>
    </div>
  );
};

const FormDetail = ({
  classes,
  form,
  clientId,
  consignees,
  clearingAgents,
  terms,
  usersData,
  sellingterms,
  dealtypes,
  activeCurrencies,
  bankingDetails,
  forceReloadConsignees,
  toggleDirty,
  setConsignees,
  setEditingTerms,
  setClearingAgents,
  setEditingConsignees,
}) => {
  const isDirty = useMemo(() => form.getState().dirty, [form.getState().dirty]);

  const loadConsignees = async () => {
    const consigneesResult = await getConsigneeReadyForCombo(clientId, 0);
    setConsignees(consigneesResult);
  };

  const loadClearingAgents = async () => {
    if (isDirty) {
      form.change("clearingagent_id", null); // clear Clearing Agent field
    }

    const clearingAgents = await getClearingAgentReadyForCombo(form.getState().values["consignee_id"]);
    setClearingAgents(clearingAgents);
  };

  useEffect(() => {
    loadConsignees();
  }, [forceReloadConsignees]);

  useEffect(() => {
    loadClearingAgents();
  }, [form.getState().values["consignee_id"]]);

  useEffect(() => {
    if (clientId != 0) {
      toggleDirty(isDirty);
    }
  }, [isDirty]);

  return (
    <div>
      <table className={classes.table}>
        <tbody>
          <TableFieldCombo classes={classes} field="consignee_id" title="Consignee" data={consignees} setEditingConsignees={setEditingConsignees} addnone={true} />
          <TableFieldCombo classes={classes} field="clearingagent_id" title="Clearing Agent" data={clearingAgents} addnone={true} />
          <TableFieldCombo classes={classes} field="terms_id" title="Payment Terms" data={terms} setEditingTerms={setEditingTerms} addnone={true} />
          <TableFieldCombo classes={classes} field="trader_id" title="Trader" data={usersData} addnone={true} />
          <TableFieldCombo classes={classes} field="sellingterms" title="Selling Deal Type" data={sellingterms} addnone={true} />
          <TableFieldCombo classes={classes} field="dealtype" title="Selling Terms" data={dealtypes} addnone={true} />
          <TableFieldCombo classes={classes} field="currency_id" title="Currency" data={activeCurrencies} addnone={true} />
          <TableFieldCombo classes={classes} field="statement_account_id" title="Statement Account" data={bankingDetails} addnone={true} />
          <TableFieldText classes={classes} field="credit_limit" title="Credit Limit" disabled={false} multiline={false} required={false} />
          <TableFieldDate classes={classes} field="credit_limit_exp" title="Credit Limit exp" />
        </tbody>
      </table>
    </div>
  );
};

export default withStyles(styles)(ClientEditFinFormUnstyled);

const CalenderCustomInput = forwardRef((props: any, ref: any) => (
  <Button name="CalenderCustomInput" variant="contained" color="primary" onClick={props.onClick} style={{ marginTop: "5px", width: "250px" }}>
    {props.value}
  </Button>
));

const RenderDatePicker = ({ name, input, input: { value, onChange } }) => (
  <ReactDatePicker
    name={name}
    showWeekNumbers
    locale="en-GB"
    dateFormat="dd-MM-yyyy"
    selected={value && isValid(value) ? value : null}
    placeholderText="click here to select a date"
    customInput={<CalenderCustomInput />}
    onChange={(date) => {
      if (isValid(date)) {
        input.onChange(new Date(date));
      } else {
        input.onChange(null);
      }
    }}
  />
);

const TableFieldDate: FC<{ field: string; title: string } & WithStyles<typeof styles>> = ({ classes, field, title }) => (
  <tr>
    <td className={classes.tableRowFieldTitle} style={{ verticalAlign: "top", paddingTop: "13px" }}>
      <span className={classes.tableCellLabel}>{`${title}:`}</span>
    </td>
    <td className={classes.tableCellDetailDate}>
      <Field name={field} component={RenderDatePicker as any} />
    </td>
  </tr>
);

const TableFieldText: FC<{ field: string; title: string; disabled: boolean; multiline: boolean; required: boolean } & WithStyles<typeof styles>> = (props) => {
  const { classes, title, field, disabled, multiline, required } = props;

  return (
    <tr>
      <td className={classes.tableRowFieldTitle} style={{ verticalAlign: "top", paddingTop: "13px" }}>
        <span>{`${title}: `}</span>
      </td>
      <td className={classes.tableRowFieldDataText}>
        <Field
          name={field}
          key={`body_value_${field}`}
          component={TextField}
          type="text"
          className={classes.tableRowFieldDataText}
          multiline={multiline}
          rows={multiline ? "4" : "1"}
          required={required}
          disabled={disabled}
          format={(value) => {
            return numeral(value).format("0,0.00").toString();
          }}
          formatOnBlur={true}
        />
      </td>
    </tr>
  );
};

const TableFieldCombo: FC<
  {
    classes: any;
    title: string;
    field: string;
    data: any;
    addnone: boolean;
    setEditingConsignees?: any;
    setEditingTerms?: any;
  } & WithStyles<typeof styles>
> = (props) => {
  const { classes, title, field, data, setEditingConsignees, setEditingTerms, addnone } = props;
  const style = { width: `${setEditingConsignees || setEditingTerms ? "320px" : "auto"}` };
  return (
    <tr>
      <td className={classes.tableRowFieldTitle}>{`${title}:`}</td>
      <td className={classes.tableRowFieldData}>
        <Field
          native
          name={field}
          style={style}
          component={Select as any}
          formControlProps={{
            className: classes.tableRowFieldData,
          }}
        >
          {addnone && (
            <option key={`none_1`} value={0}>
              {`NONE`}
            </option>
          )}
          {(data || []).map((item, index) => (
            <option key={`${item.value}${index}`} value={item.value}>
              {item.display}
            </option>
          ))}
        </Field>
        {setEditingConsignees && (
          <span style={{ float: "right", marginTop: "-35px" }}>
            <Tooltip title="Open Consignee Maintenance" placement="top-end">
              <Button variant="contained" color="primary" onClick={() => setEditingConsignees(true)}>
                <Settings />
              </Button>
            </Tooltip>
          </span>
        )}
        {setEditingTerms && (
          <span style={{ float: "right", marginTop: "-35px" }}>
            <Tooltip title="Open Terms Maintenance" placement="top-end">
              <Button variant="contained" color="primary" onClick={() => setEditingTerms(true)}>
                <Settings />
              </Button>
            </Tooltip>
          </span>
        )}
      </td>
    </tr>
  );
};
