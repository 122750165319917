import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";

import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";

import { varietiesWithCommodity } from "../../lib/api/variety";
import { commoditiesAllSortedMappedforCombo } from "../../lib/api/commodity";
import { classesAllSortedMappedforCombo } from "../../lib/api/classes";
import { weeksOrdered, getFinancialYearSelected } from "../../lib/api/week";
import { advanceContracts } from "../../lib/api/advancecontracts";
import { advancecontractscommoditiesFull } from "../../lib/api/advancecontractscommodities";
import { advancecontractsvarietiesFull } from "../../lib/api/advancecontractsvarieties";
import { getAdvanceContractsPayoutReadyForCombo } from "../../lib/api/advancecontractspayouttypes";
import { getProducerReadyForCombo } from "../../lib/api/producer";

import { Form, Field } from "react-final-form";
import { TextField, Select } from "final-form-material-ui";

import numeral from "numeral";
import { advancecontractsgradesFull } from "../../lib/api/advancecontractsgrades";
import { CircularProgress } from "@material-ui/core";
import { advancecontractspackcodesFull } from "../../lib/api/advancecontractpackcodes";
import { getDistinctPackCodes } from "../../lib/api/pack";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
      display: "flex",
    },
    tableRowFieldInputCurrency: {
      width: "65.5px",
    },
    tableRowFieldTitle: {
      paddingRight: theme.spacing(1),
      textAlign: "right",
      minWidth: "145px",
      height: "32px",
    },
    tableRowFieldData: {
      paddingRight: theme.spacing(1),
      textAlign: "left",
    },
    select: {
      minWidth: 250,
      marginLeft: 5,
      maxWidth: 250,
    },
    textField: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      marginBottom: "-16px",
    },
  });

type AdvanceContractFormProps = {
  id: any;
  onClose: any;
  onSubmit: any;
  producerid: any;
  itemExists: boolean;
  isDuplicate: boolean;
} & WithStyles<typeof styles>;

class AdvanceContractFormPropsUnstyled extends React.Component<AdvanceContractFormProps, any> {
  state = {
    classes: undefined,
    item: {
      id: 0,
      weekfrom: 0,
      weekto: 0,
      commodity: [null],
      variety: [null],
      grade: [null],
      packcode: [null],
      cad: 0,
      eur: 0,
      gbp: 0,
      usd: 0,
      zar: 0,
      producer_id: 0,
      payoutSource: 1,
      payoutLeadNumber: 1,
    },
    onSubmit: undefined,
    onClose: undefined,
    id: 0,
    commodityAllData: [],
    grades: [],
    varieties: [],
    filteredVarieties: [],
    fromWeeks: [],
    toWeeks: [],
    producerComboData: [],
    payoutComboData: [],
    payoutLeadNumberData: [
      { value: 1, display: "1" },
      { value: 2, display: "2" },
      { value: 3, display: "3" },
    ],
    isDuplicate: false,
    packcodes: [],
    loading: true,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
    this.state.onSubmit = props.onSubmit;
    this.state.onClose = props.onClose;
    this.state.id = props.id;
    if (props.producerid !== 0) {
      this.state.item.producer_id = props.producerid;
    }
    this.state.isDuplicate = props.isDuplicate;
  }

  async componentWillMount() {
    this.setState({ loading: true });

    const results = await this.loadData();
    console.log("🚀 ~ AdvanceContractFormPropsUnstyled ~ componentWillMount ~ results:", results);

    this.setState({
      commodityAllData: results[0],
      fromWeeks: results[1].data,
      toWeeks: results[1].data,
      grades: results[2],
      producerComboData: results[3],
      payoutComboData: results[4],
      packcodes: results[5],
      varieties: results[6],
      loading: false,
    });

    if (this.state.id.toString() !== "0") {
      this.getItem(this.state.id);
    }
  }

  loadData = async () => {
    return await Promise.all([
      commoditiesAllSortedMappedforCombo(),
      weeksOrdered(getFinancialYearSelected()),
      classesAllSortedMappedforCombo(),
      getProducerReadyForCombo(),
      getAdvanceContractsPayoutReadyForCombo(),
      getDistinctPackCodes(),
      varietiesWithCommodity(),
    ]);
  };

  getItem = async (id: number) => {
    const [advancecontract, advancecontractcommodities, advancecontractvarieties, advancecontractgrades, advancecontractpackcodes] = await Promise.all([
      advanceContracts.single(id),
      advancecontractscommoditiesFull(id),
      advancecontractsvarietiesFull(id),
      advancecontractsgradesFull(id),
      advancecontractspackcodesFull(id),
    ]);

    // commodities
    if (advancecontractcommodities) {
      advancecontract.commodity = advancecontractcommodities.commodityidlist.split(",").map((value) => value);
    } else {
      advancecontract.commodity = [null];
    }
    // varieties
    if (advancecontractvarieties) {
      advancecontract.variety = advancecontractvarieties.varietyidlist.split(",").map((value) => value);
    } else {
      advancecontract.variety = [null];
    }
    // grades
    if (advancecontractgrades) {
      advancecontract.grade = advancecontractgrades.gradesidlist.split(",").map((value) => parseInt(value));
    } else {
      advancecontract.grade = [null];
    }
    // packcodes
    if (advancecontractpackcodes && advancecontractpackcodes.packcodeslist && advancecontractpackcodes.packcodeslist != "") {
      advancecontract.packcode = advancecontractpackcodes.packcodeslist.split(",").map((value) => value && value.trim());
    } else {
      advancecontract.packcode = [null];
    }

    if (advancecontract.commodity.length > 0) {
      this.handleChangeCommodity(advancecontract.commodity);
    }
    this.setState({ item: { ...advancecontract } });
  };

  handleChangeCommodity = (value: string[]) => {
    const filteredVarieties = this.state.varieties
      .reduce((arr, vr) => {
        if (value.includes(vr.commodity_id)) {
          arr.push({ id: vr.id, display: `${vr.name} (${vr.code})`, name: vr.name });
        }
        return arr;
      }, [])
      .sort((a, b) => a.name.localeCompare(b.name));
    this.setState({ filteredVarieties });
  };

  updateToWeekSelections = (fromWeekId, item) => {
    if (item) {
      const fromWeek = JSON.parse(JSON.stringify(this.state.fromWeeks)).filter((weeks) => weeks.id === fromWeekId)[0];
      const toWeeks = this.state.fromWeeks.filter((week) => week.period_start >= fromWeek.period_start);

      this.setState({ toWeeks });
    }
  };

  render() {
    const { classes } = this.state;

    return (
      <div className={classes.root}>
        <Form
          initialValues={{
            ...this.state.item,
            cad: numeral(this.state.item.cad).format("0.00"),
            eur: numeral(this.state.item.eur).format("0.00"),
            gbp: numeral(this.state.item.gbp).format("0.00"),
            usd: numeral(this.state.item.usd).format("0.00"),
            zar: numeral(this.state.item.zar).format("0.00"),
          }}
          onSubmit={(values: any) => {
            const submitData = {
              id: values.id,
              weekfrom: values.weekfrom,
              weekto: values.weekto,
              cad: values.cad,
              eur: values.eur,
              gbp: values.gbp,
              usd: values.usd,
              zar: values.zar,
              commodity: values.commodity,
              variety: values.variety,
              grade: values.grade,
              packcode: values.packcode,
              producer_id: values.producer_id,
              payoutSource: values.payoutSource,
              payoutLeadNumber: values.payoutLeadNumber,
            };
            this.state.onSubmit(submitData);
          }}
          validate={(values) => {
            let errors = {};
            if (!values["producer_id"] || values["producer_id"] === 0) {
              errors = { ...errors, producer_id: "Please select a producer." };
            }
            if (!values["weekfrom"] || values["weekfrom"] === 0) {
              errors = { ...errors, weekfrom: "Please select a WeekFrom." };
            }
            if (!values["weekto"] || values["weekto"] === 0) {
              errors = { ...errors, weekto: "Please select a Weekto." };
            }
            // if (!values["gradeid"] || values["gradeid"] === 0) {
            //   errors = { ...errors, gradeid: "Please select a Grade." };
            // }
            return errors;
          }}
          render={({ handleSubmit, form }) => (
            <form id={this.state.item.id.toString()} onSubmit={handleSubmit}>
              {this.state.loading && <CircularProgress size={15} />}
              <table style={{ marginLeft: "15px" }}>
                <tbody>
                  <tr>
                    <td className={classes.tableRowFieldTitle}>
                      <span>Producer:</span>
                    </td>
                    <td colSpan={4} className={classes.tableRowFieldData}>
                      <Field
                        className={classes.tableRowFieldInput}
                        name="producer_id"
                        component={Select as any}
                        formControlProps={{ className: classes.select }}
                        value={this.state.item.producer_id}
                      >
                        {this.state.producerComboData.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.display}
                          </MenuItem>
                        ))}
                      </Field>
                    </td>
                  </tr>

                  <tr>
                    <td className={classes.tableRowFieldTitle}>
                      <span>From Week:</span>
                    </td>
                    <td colSpan={4} className={classes.tableRowFieldData}>
                      <Field
                        fullWidth
                        className={classes.tableRowFieldInput}
                        name="weekfrom"
                        component={Select as any}
                        formControlProps={{ className: classes.select }}
                        parse={(value) => {
                          this.updateToWeekSelections(value, form.getState().values);
                          return value;
                        }}
                      >
                        {this.state.fromWeeks.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.week}
                          </MenuItem>
                        ))}
                      </Field>
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.tableRowFieldTitle}>
                      <span>To Week:</span>
                    </td>
                    <td colSpan={4} className={classes.tableRowFieldData}>
                      <Field className={classes.tableRowFieldInput} name="weekto" component={Select as any} formControlProps={{ className: classes.select }}>
                        {this.state.toWeeks.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.week}
                          </MenuItem>
                        ))}
                      </Field>
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.tableRowFieldTitle}>
                      <span>Commodity:</span>
                    </td>
                    <td colSpan={4} className={classes.tableRowFieldData}>
                      <Field
                        disabled={false}
                        multiple
                        value={this.state.item.variety}
                        className={classes.tableRowFieldInput}
                        name="commodity"
                        component={Select as any}
                        formControlProps={{ className: classes.select }}
                        parse={(value) => {
                          // user selected ALL
                          if (value[value.length - 1] == null) {
                            this.setState({ filteredVarieties: [] });
                            return [null];
                          }
                          const filtered = value.filter((v) => v);
                          this.handleChangeCommodity(filtered);
                          return filtered;
                        }}
                      >
                        <MenuItem key={0} value={null}>
                          {"ALL COMMODITIES"}
                        </MenuItem>
                        {this.state.commodityAllData.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.display}
                          </MenuItem>
                        ))}
                      </Field>
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.tableRowFieldTitle}>
                      <span>Variety:</span>
                    </td>
                    <td colSpan={4} className={classes.tableRowFieldData}>
                      <Field
                        disabled={!this.state.varieties || this.state.varieties.length === 0}
                        multiple
                        value={this.state.item.variety}
                        className={classes.tableRowFieldInput}
                        name="variety"
                        component={Select as any}
                        formControlProps={{ className: classes.select }}
                        parse={(value) => {
                          // user selected ALL
                          if (value[value.length - 1] == null) {
                            return [null];
                          }
                          return value.filter((v) => v);
                        }}
                      >
                        <MenuItem key={0} value={null}>
                          {"ALL VARIETIES"}
                        </MenuItem>
                        {(this.state.filteredVarieties || []).map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.display}
                          </MenuItem>
                        ))}
                      </Field>
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.tableRowFieldTitle}>
                      <span>Grade:</span>
                    </td>
                    <td colSpan={4} className={classes.tableRowFieldData}>
                      <Field
                        disabled={!this.state.grades || this.state.grades.length === 0}
                        multiple
                        value={this.state.item.grade}
                        className={classes.tableRowFieldInput}
                        name="grade"
                        component={Select as any}
                        formControlProps={{ className: classes.select }}
                        parse={(value) => {
                          // user selected ALL
                          if (value[value.length - 1] == null) {
                            return [null];
                          }
                          return value.filter((v) => v);
                        }}
                      >
                        <MenuItem key={0} value={null}>
                          {"ALL GRADES"}
                        </MenuItem>
                        {(this.state.grades || []).map((item) => (
                          <MenuItem key={item.value} value={item.id}>
                            {item.value}
                          </MenuItem>
                        ))}
                      </Field>
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.tableRowFieldTitle}>
                      <span>Pack Code:</span>
                    </td>
                    <td colSpan={4} className={classes.tableRowFieldData}>
                      <Field
                        disabled={!this.state.packcodes || this.state.packcodes.length === 0}
                        multiple
                        value={this.state.item.packcode}
                        className={classes.tableRowFieldInput}
                        name="packcode"
                        component={Select as any}
                        formControlProps={{ className: classes.select }}
                        parse={(value) => {
                          // user selected ALL
                          if (value[value.length - 1] == null) {
                            return [null];
                          }
                          return value.filter((v) => v);
                        }}
                      >
                        <MenuItem key={0} value={null}>
                          {"ALL PACK CODES"}
                        </MenuItem>
                        {this.state.packcodes.map((item) => (
                          <MenuItem key={item.code} value={item.code}>
                            {item.code}
                          </MenuItem>
                        ))}
                      </Field>
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.tableRowFieldTitle}>
                      <span>Payout Source:</span>
                    </td>
                    <td colSpan={4} className={classes.tableRowFieldData}>
                      <Field
                        className={classes.tableRowFieldInput}
                        name="payoutSource"
                        component={Select as any}
                        formControlProps={{ className: classes.select }}
                        value={this.state.item.payoutSource}
                      >
                        {this.state.payoutComboData.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.value}
                          </MenuItem>
                        ))}
                      </Field>
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.tableRowFieldTitle}>
                      <span>LeadNumber:</span>
                    </td>
                    <td colSpan={4} className={classes.tableRowFieldData}>
                      <Field
                        className={classes.tableRowFieldInput}
                        name="payoutLeadNumber"
                        component={Select as any}
                        formControlProps={{ className: classes.select }}
                        value={this.state.item.payoutLeadNumber}
                      >
                        {this.state.payoutLeadNumberData.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.display}
                          </MenuItem>
                        ))}
                      </Field>
                    </td>
                  </tr>
                  <tr className={classes.tableRow}>
                    <td className={classes.tableRow}>
                      <span style={{ marginRight: "8px" }}>Advance Amount:</span>
                    </td>
                    <td colSpan={1} className={classes.tableRowFieldData}>
                      <Field className={classes.tableRowFieldInputCurrency} required name="cad" component={TextField} type="text" label="CAD" />
                    </td>
                    <td colSpan={1} className={classes.tableRowFieldData}>
                      <Field className={classes.tableRowFieldInputCurrency} required name="eur" component={TextField} type="text" label="EUR" />
                    </td>
                    <td colSpan={1} className={classes.tableRowFieldData}>
                      <Field className={classes.tableRowFieldInputCurrency} required name="gbp" component={TextField} type="text" label="GBP" />
                    </td>
                    <td colSpan={1} className={classes.tableRowFieldData}>
                      <Field className={classes.tableRowFieldInputCurrency} required name="usd" component={TextField} type="text" label="USD" />
                    </td>
                    <td colSpan={1} className={classes.tableRowFieldData}>
                      <Field className={classes.tableRowFieldInputCurrency} required name="zar" component={TextField} type="text" label="ZAR" />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div style={{ display: "flex", margin: "20px 16px -5px", justifyContent: "space-between" }}>
                <div>
                  {this.props.itemExists && (
                    <>
                      <p style={{ color: "red", lineHeight: "10px" }}>An advance contract with this data already exists.</p>
                      <p style={{ color: "red", lineHeight: "10px" }}>Please the make necessary changes.</p>
                    </>
                  )}
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                  {!this.state.isDuplicate && (
                    <Button style={{ marginRight: "10px" }} color="secondary" variant="outlined" onClick={this.state.onClose}>
                      <Close />
                    </Button>
                  )}
                  <Button type="submit" color="primary" variant="outlined">
                    <Check />
                  </Button>
                </div>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

export default withStyles(styles)(AdvanceContractFormPropsUnstyled);
