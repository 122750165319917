import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import { Form, FormProps } from "react-final-form";
import moment from "moment";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import { copyOrderByOrderId, getOrderById, OrdersFull } from "../lib/api/piorder";
import { OrderHeader } from "../instructions/instructionordersHeader";
import { GreenButton, RedButton } from "../lib/components/ColorButtons";
import { GenerateErrorMessage } from "../lib/helpers/string_methods";
import { SnackContext } from "../lib/context/SnackContext";
import { LinearProgress } from "@material-ui/core";
import { history } from "../lib/history";
import { VarietyType } from "../lib/api/variety";
import { WeekType } from "../lib/api/week";
import { ProducerType } from "../lib/api/producer";
import { FormApi } from "final-form";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    actionsContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      gap: theme.spacing(1),
    },
  });

type Props = {
  weeks: WeekType[];
  orders: OrdersFull[];
  producers: ProducerType[];
  varieties: VarietyType[];
  orderCopiedId: number;
  handleClose(): void;
} & WithStyles<typeof styles>;

const OrderCopyUnstyled: FC<Props> = ({ classes, orders, orderCopiedId, producers, weeks, varieties, handleClose }) => {
  const { updateSnack } = useContext(SnackContext);
  const [isDuplicate, setIsDuplicate] = useState(true);

  const [loading, setLoading] = useState(true);
  const [orderData, setOrderData] = useState({ variety: 0, exchange: "" });

  const producersMapped = useMemo(
    () => producers.sort((a, b) => a.name.localeCompare(b.name)).map((producer) => ({ value: producer.code, display: `${producer.name}` })),
    [producers],
  );

  const weeksMapped = useMemo(
    () => weeks.sort((a, b) => a.week.localeCompare(b.week)).map((week) => ({ value: week.id, display: week.week + " (" + moment(week.period_start).format("DD-MM-YYYY") + ")" })),
    [weeks],
  );

  const varietyCommodity = useMemo(() => varieties.find((v) => v.id == orderData.variety), [varieties, orderData]);

  const loadData = async () => {
    setLoading(true);
    try {
      const [orderResult] = await Promise.all([getOrderById(orderCopiedId)]);
      const order = orderResult[0];
      const exchange = JSON.parse(order.exchange);

      setOrderData({
        ...order,
        exchange,
        exworks: order.exworks ? "true" : "false",
        fixed: order.fixed ? "true" : "false",
        local: order.local ? "true" : "false",
      });
    } catch (error) {
      const err = GenerateErrorMessage(error, "Failed to get data");
      updateSnack({ color: "red", message: err, show: true });
    }
    setLoading(false);

    return () => {
      setOrderData(null);
    };
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      delete values["id"];
      delete values["commodity"];
      delete values["created_at"];
      delete values["updated_at"];

      const result = await copyOrderByOrderId({ ...values, exchange: JSON.stringify(values.exchange) }, orderCopiedId);
      history.push(`/instruction/${result}/edit`);
    } catch (error) {
      const err = GenerateErrorMessage(error, "Failed to copy order");
      updateSnack({ color: "red", message: err, show: true });
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Dialog fullScreen={false} open onClose={handleClose} maxWidth="xl">
      <DialogTitle>Copy Order</DialogTitle>
      <DialogContent>
        {loading && <LinearProgress />}
        <Form
          initialValues={{ ...orderData, id: -1 }}
          onSubmit={handleSubmit}
          render={({ form, handleSubmit }) => (
            <form onSubmit={handleSubmit} className={classes.root}>
              <OrderHeader
                form={form}
                groupMaster
                weeksMapped={weeksMapped}
                variety={varietyCommodity}
                producersMapped={producersMapped}
                exchange={orderData.exchange}
                handleChangeFarm={() => {}}
                handleSetExchange={() => {}}
              />
              <DuplicateCheck isDuplicate={isDuplicate} setIsDuplicate={setIsDuplicate} orders={orders} form={form} />
              <div className={classes.actionsContainer}>
                <RedButton type="button" variant="contained" onClick={handleClose}>
                  Cancel
                </RedButton>
                <GreenButton type="submit" variant="contained" disabled={isDuplicate || loading}>
                  Create copy
                </GreenButton>
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

type DuplicateCheckProps = {
  orders: any[];
  isDuplicate: boolean;
  setIsDuplicate: (isDuplicate: boolean) => void;
  form: FormApi<Record<string, any>, Partial<Record<string, any>>>;
};

const DuplicateCheck: FC<DuplicateCheckProps> = ({ orders, isDuplicate, setIsDuplicate, form }) => {
  useEffect(() => {
    const { farm, week, variety } = form.getState().values;
    const duplicate = orders.some((order) => order.week_id == week && parseInt(order.variety_id) == variety && order.producer == farm);
    setIsDuplicate(duplicate);
  }, [form.getState().values]);

  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
      <p style={{ color: "red", fontSize: "1rem", margin: 0 }}>{isDuplicate && "Duplicate Order found for farm, variety and week."}</p>
    </div>
  );
};

export const OrderCopy = withStyles(styles)(OrderCopyUnstyled);
