import React, { useEffect, useMemo, useState } from "react";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";

import numeral from "numeral";
import { SelectColumn } from "react-data-grid";

import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import TextEditor from "../lib/components/TextEditor";
import DropdownEditor from "./CustomDropdownEditor";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      appearance: "none",
      boxSizing: "border-box",
      width: "100%",
      height: "100%",
      padding: "0px 6px 0 6px",
      border: "2px solid #ccc",
      verticalAlign: "top",
      color: "black",
      textAlign: "right",
      fontSize: "15px",
      "&:focus": {
        borderColor: "black",
        outline: "none",
      },
      "&::placeholder": {
        color: "#999",
        opacity: 1,
      },
    },
  });

const CountEditorUnstyled = ({ row, column, counts, onRowChange }) => {
  const [count, setCount] = useState(row[column.key]);
  const [open, setOpen] = useState(true);

  const dataItems = useMemo(() => counts.filter((item) => item.pack_id == row.pack_id).sort((a, b) => Number(a.code) - Number(b.code)), [counts]);

  const handleChange = (e) => {
    const newvalue = e.target.value.sort((a, b) => Number(a) - Number(b)).join("/");
    setCount(newvalue);
  };

  const handleChangeComplete = () => {
    onRowChange({ ...row, [column.key]: count }, true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleKeyPress = (event) => {
    // tab key press
    if (event.keyCode === 9 || event.keyCode === 27) {
      onRowChange(row, true);
    }
  };

  return (
    <Select
      autoFocus
      open={open}
      multiple={true}
      onClose={handleClose}
      value={count.split("/")}
      onBlur={handleChangeComplete}
      onChange={handleChange}
      style={{ width: "100%", height: "100%", backgroundColor: "lightgray" }}
    >
      {dataItems.map((item) => (
        <MenuItem key={item.id} value={item.code} onKeyDown={handleKeyPress}>
          {item.code}
        </MenuItem>
      ))}
    </Select>
  );
};

const CountEditor = withStyles(styles)(CountEditorUnstyled);

const checkboxStyles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "18px",
      width: "18px",
      backgroundColor: "#eee",
      border: "none",
    },
  });

const CheckboxEditorUnstyled = ({ classes, row, column, onRowChange }) => {
  const ref = React.useRef(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
      ref.current.select();
    }
  }, [ref]);

  const handleChange = (event) => {
    onRowChange({ ...row, [column.key]: event.target.checked });
  };

  return <input className={classes.root} ref={ref} type="checkbox" checked={row[column.key]} onChange={handleChange} />;
};

const CheckboxEditor = withStyles(checkboxStyles)(CheckboxEditorUnstyled);

const CheckboxFormatter = ({ row, column }) => {
  const data = row[column.key];
  if (data && data == 1) {
    return <Check style={{ color: "green" }} />;
  } else {
    return <Close style={{ color: "red" }} />;
  }
};

const DirtyFormatter = ({ row, column }) => {
  const data = row[column.key];
  if (row) {
    const colorNew = row.isDirty && row.isDirty == 1 ? "red" : "black";
    return <div style={{ color: colorNew }}>{data}</div>;
  }
  return <div>{data}</div>;
};

const cellWidthGroupLine = 45;
const cellWidthTCGrouped = 75;
const cellWidthBrand = 88;

const cellWidthTinyX = 35;
const cellWidthTiny = 55;
const cellWidthSmallXXX = 85;
const cellWidthSmallXX = 90;
const cellWidthSmallX = 105;
const cellWidthSmall = 115;
const cellWidthMedium = 150;

export const InstructionGridColumns = (
  IsFixed,
  targetsRegion,
  targetsMarket,
  targetsCountry,
  counts,
  classesArr,
  palletspecsCarton,
  palletspecsCode,
  coldrooms,
  brands,
  inventories,
  mgps,
  handleCopy,
  handleRemove,
) => {
  let columns = [
    SelectColumn,
    { ...SelectColumn, key: "drag", name: "", headerRenderer: () => <></> },
    // {
    //   key: "actions",
    //   name: "Actions",
    //   width: 80,
    //   formatter: ({ row }) => {
    //     const actions = orderActions(handleCopy, handleRemove, row.id, row.isDirty);
    //     return (
    //       <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
    //         <CellActionsFormatter actions={actions} />
    //       </div>
    //     );
    //   },
    // },
    { key: "groupnum", name: "Order", editable: true, editor: (props) => <TextEditor {...props} />, formatter: DirtyFormatter, width: cellWidthTiny },
    { key: "groupline", name: "Line", editable: true, editor: (props) => <TextEditor {...props} />, formatter: DirtyFormatter, width: cellWidthGroupLine },
    { key: "volume", name: "Volume", editable: true, editor: (props) => <TextEditor {...props} />, formatter: DirtyFormatter, width: cellWidthSmallX },
    {
      key: "region",
      name: "TR",
      width: cellWidthSmallXX,
      editor: (props) => <DropdownEditor {...props} options={targetsRegion} />,
      formatter: DirtyFormatter,
      editorOptions: {
        editOnClick: true,
      },
    },
    {
      key: "market",
      name: "TM",
      width: cellWidthSmallXX,
      editor: (props) => {
        const { region } = props.row;
        const options = region ? targetsMarket.filter((tm) => tm.region == region) : [];
        return <DropdownEditor {...props} options={options} />;
      },
      formatter: DirtyFormatter,
      editorOptions: {
        editOnClick: true,
      },
    },
    {
      key: "country",
      name: "TC",
      width: cellWidthSmallXX,
      editor: (props) => {
        const { region, market } = props.row;
        const options = region && market ? targetsCountry.filter((tm) => (tm.region == region && tm.market == market) || tm.id === 0) : [];
        return <DropdownEditor {...props} options={options} />;
      },
      formatter: DirtyFormatter,
      editorOptions: {
        editOnClick: true,
      },
    },
    // { key: "tcgrouped", name: "Grouped", formatter: CheckboxFormatter, width: cellWidthTCGrouped, editor: (props) => <CheckboxEditor {...props} /> },
    {
      key: "class",
      name: "Class",
      width: cellWidthTiny,
      editor: (props) => <DropdownEditor {...props} options={classesArr} />,
      formatter: DirtyFormatter,
      editorOptions: {
        editOnClick: true,
      },
    },
    {
      key: "pack_code",
      name: "Pack",
      width: cellWidthSmallX,
      editor: (props) => <DropdownEditor {...props} options={palletspecsCode} />,
      formatter: DirtyFormatter,
      editorOptions: {
        editOnClick: true,
      },
    },
    {
      key: "pack_carton",
      name: "Ctns",
      width: cellWidthSmallX,
      editor: (props) => <DropdownEditor {...props} options={palletspecsCarton} filter={{ field: "code", value: props.row.pack_code }} />,
      formatter: DirtyFormatter,
      editorOptions: {
        editOnClick: true,
      },
    },
    { key: "conventional", name: "Conventional", formatter: CheckboxFormatter, width: 75, editor: (props) => <CheckboxEditor {...props} /> },
    {
      key: "count",
      name: "Count",
      width: cellWidthSmallXXX,
      formatter: DirtyFormatter,
      editor: (props) => <CountEditor {...props} counts={counts} />,
      editorOptions: {
        editOnClick: true,
      },
    },
    {
      key: "countconstraint",
      name: "Constraint",
      editable: true,
      width: cellWidthMedium,
      editor: (props) => <TextEditor {...props} />,
      formatter: DirtyFormatter,
    },
    {
      key: "coldroom",
      name: "Coldroom",
      width: cellWidthMedium,
      editor: (props) => <DropdownEditor {...props} options={coldrooms} />,
      formatter: DirtyFormatter,
      editorOptions: {
        editOnClick: true,
      },
    },
    {
      key: "brand",
      name: "Brand",
      width: cellWidthBrand,
      editor: (props) => <DropdownEditor {...props} options={brands} />,
      formatter: DirtyFormatter,
      editorOptions: {
        editOnClick: true,
      },
    },
    {
      key: "inventory_display",
      name: "Inventory",
      width: cellWidthMedium,
      editor: (props) => <DropdownEditor {...props} options={inventories} />,
      formatter: DirtyFormatter,
      editorOptions: {
        editOnClick: true,
      },
    },
    {
      key: "mgp",
      name: "Selling Terms",
      width: cellWidthSmall,
      editor: (props) => <DropdownEditor {...props} options={mgps} />,
      formatter: DirtyFormatter,
      editorOptions: {
        editOnClick: true,
      },
    },
    { key: "fob", name: !IsFixed ? "FOB" : "Purchase Price", editable: true, width: cellWidthSmallX, editor: (props) => <TextEditor {...props} />, formatter: DirtyFormatter },
  ];

  if (!IsFixed) {
    columns.push(
      { key: "rangemin", name: "RANGE MIN", editable: true, width: cellWidthSmallX, editor: (props) => <TextEditor {...props} />, formatter: DirtyFormatter },
      { key: "rangemax", name: "RANGE MAX", editable: true, width: cellWidthSmallX, editor: (props) => <TextEditor {...props} />, formatter: DirtyFormatter },
      { key: "dip", name: "DIP", editable: false, width: cellWidthSmallX, formatter: DirtyFormatter },
      { key: "dipmin", name: "DIP MIN", editable: false, width: cellWidthSmallX, formatter: DirtyFormatter },
      { key: "dipmax", name: "DIP MAX", editable: false, width: cellWidthSmallX, formatter: DirtyFormatter },
    );
  }
  columns.push({ key: "comment", name: "Comment", editable: true, width: cellWidthSmallX, editor: (props) => <TextEditor {...props} />, formatter: DirtyFormatter });
  columns = columns.map((c) => ({ ...c, ...defaultColumnProperties }));
  return columns;
};

const defaultColumnProperties = {
  resizable: true,
};

export const RecalculateRowValues = (row, commission, exchange, targetResult, palletspecs, local, fixed, estimatedcosts, variety, week, producerFinanceEstimatedCostItemGroups) => {
  let calculation = "";

  // * calculate dip
  let dipValue = 0;
  let rangemin = row.rangemin;
  let rangemax = row.rangemax;
  let exchangeRate = 1;

  // * subtract commision
  const commissionCalc = commission / 100;

  dipValue = row.fob * commissionCalc;
  dipValue = row.fob - dipValue;

  if (row.fob) {
    calculation += " (fob)" + row.fob.toString();
  }

  const targetsIdentified = targetResult.filter((target) => target.id == row.target_id);
  // * convert to currency if local
  if (targetsIdentified.length > 0) {
    switch (targetsIdentified[0].currency.toLowerCase()) {
      case "usd":
        exchangeRate = exchange.usd;
        break;
      case "cad":
        exchangeRate = exchange.cad;
        break;
      case "eur":
        exchangeRate = exchange.eur;
        break;
      case "gbp":
        exchangeRate = exchange.gbp;
        break;
      default:
        break;
    }
  }
  calculation += " (exch)" + exchangeRate.toString();

  if (local) {
    dipValue = dipValue * exchangeRate;
    rangemin = rangemin * exchangeRate;
    rangemax = rangemax * exchangeRate;
  }

  calculation += " (commi)" + commissionCalc;
  calculation += " (dip)" + dipValue.toString();

  // * get the pallet spec and cost estimation
  const dataItemPalletSpec = palletspecs.filter((ps) => ps.id === row.pack_id);

  if (dataItemPalletSpec && dataItemPalletSpec.length > 0) {
    const estimatedcostItem: [] = estimatedcosts.filter((ec) => {
      const ports = ec.port_id ? ec.port_id.split(",").map((port) => parseInt(port)) : [];
      if (
        ec.pack_carton == dataItemPalletSpec[0].carton &&
        (ec.country || "")
          .toString()
          .split(",")
          .find((cntry) => cntry == row.country) &&
        ec.commodity == variety.commodity_id &&
        ec.season.includes(week.financial_year) &&
        (ports.length > 0 ? ports.includes(parseInt(row.port_id)) : true) // it's true if no ports were selected for the estimated cost
      ) {
        if (row.conventional == 1) {
          if (ec.conventional == row.conventional) return ec;
        } else {
          if (ec.pack_code == row.pack_code) return ec;
        }
      }
    });

    let costestimationValue = 0,
      costestimationValueRoyalties = 0,
      costestimationValueRoyaltiesP = 0,
      costestimationValueRoyaltiesS = 0;

    if (estimatedcostItem && estimatedcostItem.length > 0) {
      estimatedcostItem.map((item: any) => {
        const columns = [item.Item1, item.Item2, item.Item3, item.Item4, item.Item5, item.Item6, item.Item7].filter((col) => {
          const group = (producerFinanceEstimatedCostItemGroups || []).find((est) => col.includes(est.name));
          if (group && group.include && !col.includes("ROYAL")) return col;
        });

        const value = columns.reduce((sum, row) => {
          const [_, value] = row.split(": ");
          sum += parseFloat(value);
          return sum;
        }, 0);

        costestimationValue = (producerFinanceEstimatedCostItemGroups || []).length === 0 ? item.value : value;
        costestimationValueRoyalties = item.valueRoyalty;
        costestimationValueRoyaltiesP = (item.valueRoyaltyP / 100) * (row.fob * exchangeRate);
        costestimationValueRoyaltiesS = item.valueRoyaltyS; // new scenario
      });
    }
    if (!local) {
      dipValue =
        dipValue -
        costestimationValue / exchangeRate -
        costestimationValueRoyalties / exchangeRate -
        costestimationValueRoyaltiesP / exchangeRate -
        costestimationValueRoyaltiesS / exchangeRate;

      calculation += " (cest)" + (costestimationValue / exchangeRate).toString();
      calculation += " (royalties R)" + (costestimationValueRoyalties / exchangeRate).toString();
      calculation += " (royalties P)" + (costestimationValueRoyaltiesP / exchangeRate).toString();
      calculation += " (royalties S)" + (costestimationValueRoyaltiesS / exchangeRate).toString();
    } else {
      dipValue = dipValue - costestimationValue - costestimationValueRoyalties - costestimationValueRoyaltiesP - costestimationValueRoyaltiesS;

      calculation += " (cest)" + costestimationValue.toString();
      calculation += " (royalties R)" + costestimationValueRoyalties.toString();
      calculation += " (royalties P)" + costestimationValueRoyaltiesP.toString();
      calculation += " (royalties S)" + costestimationValueRoyaltiesS.toString();
    }
  }

  const numeralDipV = numeral(dipValue).format("0.00");

  row.dip = numeralDipV.toString();

  const numeralDipMinV = numeral(Number(numeralDipV) + Number(rangemin)).format("0.00");
  row.dipmin = numeralDipMinV.toString();

  const numeralDipMaxV = numeral(Number(numeralDipV) + Number(rangemax)).format("0.00");
  row.dipmax = numeralDipMaxV.toString();

  const currencyPrefix = (farmLocal, currency) => {
    if (farmLocal) {
      return "ZAR ";
    } else {
      return currency.toUpperCase() + " ";
    }
  };

  const perKGPostFix = () => {
    if (dataItemPalletSpec && dataItemPalletSpec.length > 0 && (dataItemPalletSpec[0].carton == "J60B" || dataItemPalletSpec[0].carton == "J38B")) {
      return " per Kg";
    }
    return "";
  };

  const currencyPrefixValue = currencyPrefix(local, targetsIdentified.length > 0 ? targetsIdentified[0].currency.toLowerCase() : "usd");

  if (fixed) {
    row.dip = row.fob;
    row.dipmin = row.fob;
    row.dipmax = row.fob;

    row.rangemin = 0;
    row.rangemax = 0;
  }

  row.dip = currencyPrefixValue + row.dip + perKGPostFix();
  row.dipmin = currencyPrefixValue + row.dipmin + perKGPostFix();
  row.dipmax = currencyPrefixValue + row.dipmax + perKGPostFix();
  row.isDirty = 1;
  console.log(calculation);
  return row;
};
