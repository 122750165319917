import React, { useEffect, useState, FC, useContext } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Confirmation from "../../lib/components/confirmation";
import { DialogInformation } from "../../lib/components/dialoginformation";
import {
  estimatedcosts,
  EstimatedCostsData,
  estimatedcostsFull,
  execRefreshConsolidationCache,
  upsertEstimatedCostPorts,
  upsertEstimatedCostTargets,
} from "../../lib/api/estimatedcost";
import { estimatedcostsetups, estimatedcostsetupsFull } from "../../lib/api/estimatedcostsetup";
import { EstimatedCostGrid } from "./estimatedcosts/estimatedcostgrid";
import EstimatedCostsSetup from "./estimatedcostssetup/estimatedcostssetup";
import { getFinancialYearSelected } from "../../lib/api/week";
import { SnackContext } from "../../lib/context/SnackContext";
import { GenerateErrorMessage } from "../../lib/helpers/string_methods";

import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

import IconAdd from "@material-ui/icons/Add";
import { whoami } from "../../lib/api/_base";
import { User } from "../../lib/api/users";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      height: "100%",
    },
    inline: {
      display: "inline",
      position: "absolute",
      marginTop: "3px",
      marginLeft: "7px",
    },
  });

type EstimatedCostProps = {} & WithStyles<typeof styles>;

const EstimatedCostUnstyled: FC<EstimatedCostProps> = ({ classes }) => {
  const { updateSnack } = useContext(SnackContext);

  const [currentData, setCurrentData] = useState<EstimatedCostsData>(undefined);
  const [copyData, setCopyData] = useState<EstimatedCostsData>(undefined);
  const [user, setUser] = useState<Partial<User>>({ estimatedcost_edit: false });

  const [isDirty, setIsDirty] = useState(false);
  const [confirmDirty, setConfirmDirty] = useState(false);
  const [error, setError] = useState();

  const [removeID, setRemoveID] = useState(undefined);

  const [data, setData] = useState([]);
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);

  const handleCreate = () => {
    setCurrentData({
      id: -1,
      original_id: undefined,
      pack_id: undefined,
      cartons: "",
      commodity: "",
      commodity_full: "",
      created_at: "",
      exchange_cad: 0,
      exchange_eur: 0,
      exchange_gbp: 0,
      exchange_usd: 0,
      exchange_zar: 0,
      market: "",
      pack: "",
      season: getFinancialYearSelected(),
      status: 0,
      totalproducercost_cad: 0,
      totalproducercost_eur: 0,
      totalproducercost_gbp: 0,
      totalproducercost_usd: 0,
      totalproducercost_zar: 0,
      updated_at: "",
      variety: "",
      container: 1,
      conventional: 0,
      port_id: 0,
      port: "",
      countries: "",
      country: "",
      region: "",
    });
  };

  const handleEdit = async (row: EstimatedCostsData) => {
    setCurrentData({ ...row, original_id: row.id });
  };

  const handleCopy = async (row: EstimatedCostsData) => {
    setCopyData({ ...row, id: -1, original_id: row.id });
  };

  const handleCopyClose = (ecid) => {
    setCopyData(undefined);
    setCurrentData(undefined);
  };

  const handleCopyConfirm = () => {
    setCopyData(undefined);
    setCurrentData(copyData);
  };

  const submitHeadData = async (values) => {
    try {
      // TODO: refactor into transaction
      if (!values) return;

      let id = values.id;

      const ecdata = {
        data: {
          season: values.season,
          commodity: values.commodity,
          market: values.market,
          exchange_cad: values.exchange_cad,
          exchange_eur: values.exchange_eur,
          exchange_gbp: values.exchange_gbp,
          exchange_usd: values.exchange_usd,
          exchange_zar: values.exchange_zar,
          container: values.container,
          conventional: values.conventional,
        },
      };

      if (id && id != 0 && id != -1) {
        await estimatedcosts.update(id, ecdata).then(() => {
          const markets = typeof values.market === "object" ? (values.market || []).join(",") : values.market;
          setCurrentData({ ...values, market: markets });
        });
      } else {
        const result = await estimatedcosts.create(ecdata);
        id = result[0];

        // check if original id exists, treat as creating copy
        if (currentData.original_id) {
          const ecfull = await estimatedcostsetupsFull(currentData.original_id);
          for (let i = 0; i < ecfull.length; i++) {
            const ecfullitem = ecfull[i];
            const ecfullitemData = {
              data: {
                estimatedcost_id: id,
                variety: ecfullitem.variety,
                pack_id: values.pack_id,
                estimatedcostitem_id: ecfullitem.estimatedcostitem_id,
                value: ecfullitem.value,
              },
            };
            await estimatedcostsetups.create(ecfullitemData);
          }
          setCopyData(undefined);
        } else {
          const createData = {
            data: {
              estimatedcost_id: id,
              pack_id: 1,
              estimatedcostitem_id: 1,
              value: 99,
            },
          };
          await estimatedcostsetups.create(createData);
        }
        // set head data
        const markets = typeof values.market === "object" ? values.market.join(",") : values.market;
        setCurrentData({ ...values, id: id, original_id: id, market: markets });
      }
      await upsertEstimatedCostPorts({ estimatedcost_id: id, ports: (values.port_id || "").toString() });
      await upsertEstimatedCostTargets({ estimatedcost_id: id, targets: values.countries });
      execRefreshConsolidationCache();
      return id;
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error creating/updating Estimated Cost");
      updateSnack({ message: err, color: "red", show: true });
    }
  };

  const handleEditClose = () => {
    loadData();
    if (isDirty) {
      setConfirmDirty(true);
    } else {
      setCurrentData(undefined);
    }
  };

  const handleConfirmContinueClose = () => {
    setConfirmDirty(false);
  };

  const handleConfirmContinueConfirm = async () => {
    setConfirmDirty(false);
    setIsDirty(false);
    setCurrentData(undefined);
  };

  const handleErrorClose = () => {
    setError(undefined);
  };

  const handleRemove = (ecid) => {
    setRemoveID(ecid);
  };

  const handleRemoveClose = (ecid) => {
    setRemoveID(undefined);
  };

  const handleRemoveConfirm = async () => {
    try {
      await estimatedcosts.remove(removeID);
      setRemoveID(undefined);
      loadData();
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error removing Estimated Cost");
      updateSnack({ message: err, color: "red", show: true });
    }
  };

  const loadData = async () => {
    setDataLoading(true);
    const [result, userResult] = await Promise.all([estimatedcostsFull(), whoami()]);
    setData(result);
    setUser(userResult);
    setDataLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className={classes.root}>
      {user.estimatedcost_edit && (
        <div className={classes.inline}>
          <Tooltip title="Create new Average Cost Estimation">
            <div className={classes.inline}>
              <Button variant="contained" color="primary" onClick={handleCreate}>
                <IconAdd />
              </Button>
            </div>
          </Tooltip>
        </div>
      )}
      <EstimatedCostGrid handleEdit={handleEdit} handleRemove={handleRemove} handleCopy={handleCopy} data={data} dataLoading={dataLoading} loadData={loadData} />
      {error && <DialogInformation isOpen={true} handleClose={handleErrorClose} handleOK={handleErrorClose} title={"Error!"} body={`${error}`} showinput={false} />}
      {confirmDirty && (
        <Confirmation
          isOpen={currentData ? true : false}
          handleClose={handleConfirmContinueClose}
          handleConfirm={handleConfirmContinueConfirm}
          title={"Unsaved Changes"}
          body={`You have unsaved data. Do you want to continue?`}
        />
      )}
      {removeID && (
        <Confirmation
          isOpen={removeID ? true : false}
          handleClose={handleRemoveClose}
          handleConfirm={handleRemoveConfirm}
          title={"Remove Record"}
          body={`Are you sure you want to remove any data processed for the selected Estimated Cost?`}
        />
      )}
      {copyData && (
        <Confirmation
          isOpen={true}
          handleClose={handleCopyClose}
          handleConfirm={handleCopyConfirm}
          title={"Copy Record"}
          body={`Are you sure you want to copy the data of the selected Estimated Cost?`}
        />
      )}
      {currentData && (
        <Confirmation
          isOpen={true}
          body={undefined}
          title={"Estimated Costs Header"}
          handleClose={handleEditClose}
          handleConfirm={handleEditClose}
          disableBackdropClick={isDuplicate}
        >
          <EstimatedCostsSetup currentData={currentData} data={data} isDuplicate={isDuplicate} setIsDuplicate={setIsDuplicate} submitHeadData={submitHeadData} />
        </Confirmation>
      )}
    </div>
  );
};

export default withStyles(styles)(EstimatedCostUnstyled);
