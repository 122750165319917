import React, { useState, useEffect, Dispatch, SetStateAction, FC } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import { Form, Field, SupportedInputs } from "react-final-form";

import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";

import { users } from "../lib/api/users";
import ResetPassword from "./modules/ResetPassword";
import { Checkbox } from "final-form-material-ui";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "400px",
    },
    buttonCancel: {
      marginRight: `${theme.spacing(1) * 1.2}px`,
    },
    buttonReset: {
      color: "#3f51b5",
      backgroundColor: "#f5f5f5",
      marginRight: `${theme.spacing(1) * 1.2}px`,
    },
    buttonContainer: {
      display: "flex",
      flex: 1,
      flexDirection: "row",
      marginTop: `${theme.spacing(1) * 2}px`,
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
    },
    form: {},
    fieldContainer: {
      display: "grid",
      gridTemplateColumns: "200px min-content",
    },
    input: {
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontSize: "1.15rem",
      height: "35px",
      outline: "none",
      width: "200px",
      borderLeft: "none",
      borderRight: "none",
      borderTop: "none",
      borderColor: "black",
      borderWidth: 1,
    },
    label: {
      paddingRight: `${theme.spacing(1) * 2}px`,
      "& > h3": {
        display: "inline-block",
        fontSize: `${theme.typography.fontSize}px`,
      },
    },
    labelContainer: {
      display: "flex",
      flex: 0.8,
      paddingRight: `${theme.spacing(1) * 2}px`,
    },
    inputContainer: {
      display: "flex",
      alignContent: "center",
      justifyContent: "flex-start",
      flexDirection: "row",
      "& > span": {
        width: "100%",
      },
    },
    formButtons: {
      display: "flex",
      flex: 1,
      justifyContent: "flex-end",
    },
    resetPasswordButton: {
      display: "flex",
      flex: 1,
      justifyContent: "flex-start",
    },
    saveButton: {
      color: "#ffff",
      backgroundColor: "green",
    },
  });

type EditUserProps = {
  id: number;
  setSelectedUserId: Dispatch<SetStateAction<boolean>>;
  setPermissionChanged: Dispatch<SetStateAction<boolean>>;
  handleSubmit: (fields: any) => Promise<void>;
} & WithStyles<typeof styles>;

const EditUserUnstyled: React.FC<EditUserProps> = (props) => {
  const { classes, id, handleSubmit, setSelectedUserId, setPermissionChanged } = props;

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [handleResetPwd, setHandleResetPwd] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const resultUser = await users.single(id);
      setUser(resultUser);
      setLoading(false);
    };
    fetchData();
  }, [id]);

  const handleResetPassword = () => {
    setHandleResetPwd(true);
  };

  const handleParse = (value: any, name: string) => {
    setPermissionChanged(true);
    return value;
  };

  if (loading) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }

  return (
    <>
      <div className={classes.root}>
        <Form
          initialValues={user}
          onSubmit={(fields: any) => handleSubmit(fields)}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className={classes.form}>
              {handleResetPwd && <ResetPassword handleResetPwd={handleResetPwd} setHandleResetPwd={setHandleResetPwd} user={user} />}
              <div style={{ display: "flex", flexDirection: "column", fontSize: "1.15rem", gap: "1rem" }}>
                <FormField classes={classes} field="username" label="Username" fieldType="input" />
                <FormField classes={classes} field="email" label="Email" fieldType="input" />
                <FormField classes={classes} field="admin" label="Admin" fieldType="checkbox" />
                <FormField classes={classes} field="active" label="Active" fieldType="checkbox" handleParse={handleParse} />
                <FormField classes={classes} field="can_adjust" label="Stock Adjustment" fieldType="checkbox" handleParse={handleParse} />
                <FormField classes={classes} field="stockalloc_permission" label="Stock Allocation" fieldType="checkbox" handleParse={handleParse} />
                <FormField classes={classes} field="consolidation_lock_permission" label="Consolidation Lock" fieldType="checkbox" handleParse={handleParse} />
                <FormField classes={classes} field="estimatedcost_edit" label="Estimated Costs" fieldType="checkbox" handleParse={handleParse} />
              </div>
              <div className={classes.buttonContainer}>
                <div className={classes.resetPasswordButton}>
                  <Button variant="contained" className={classes.buttonReset} onClick={handleResetPassword}>
                    Reset password
                  </Button>
                </div>
                <div className={classes.formButtons}>
                  <Button variant="contained" color="secondary" className={classes.buttonCancel} onClick={() => setSelectedUserId(null)}>
                    CLOSE
                  </Button>
                  <Button className={classes.saveButton} type="submit" variant="contained">
                    SAVE
                  </Button>
                </div>
              </div>
            </form>
          )}
        />
      </div>
    </>
  );
};

type FormFieldProps = {
  label: string;
  field: string;
  fieldType: SupportedInputs | "checkbox";
  handleParse?(value: any, name: string): void;
} & WithStyles<typeof styles>;

const FormField: FC<FormFieldProps> = ({ classes, label, field, fieldType, handleParse }) => {
  return (
    <div className={classes.fieldContainer}>
      <div className={classes.labelContainer}>
        <label>{label}:</label>
      </div>
      <div className={classes.inputContainer}>
        <Field
          name={field}
          type={fieldType}
          placeholder={label}
          className={classes.input}
          parse={handleParse ? handleParse : (value) => value}
          component={fieldType === "input" ? "input" : Checkbox}
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(EditUserUnstyled);
