import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import { Form } from "react-final-form";

import InstructionGrid from "./instructiongrid";

import { OrderHeader } from "./instructionordersHeader";
import { OrderActions } from "./instructionordersActions";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    wrapper: {
      display: "grid",
      gridTemplateRows: "50px max-content calc(100vh - 120px)",
    },
  });

type InstructionEditProps = {
  onSubmit: any;
  onInstructionsDataChange: any;
  handleInstructionAdd: any;
  handleInstructionRemove: any;
  handleInstructionCopy: any;
  handleTemplateOpen: any;
  handleCreateGroup: any;
  handleResetData: any;
  handleSaveLines: any;
  handleTogglePIHead: any;
  showPIHead: any;
  order: any;
  instructions: any;
  producers: any;
  varieties: any;
  variety: any;
  weeks: any;
  targets: any;
  classesdata: any;
  counts: any;
  coldrooms: any;
  palletspecs: any;
  brands: any;
  inventories: any;
  mgps: any;
  costestimations: any;
  headerdirty: any;
  handleSetExchange: any;
  estimatedcost: any;
  groupMaster: any;
} & WithStyles<typeof styles>;

// main instruction view
const InstructionEdit: React.FunctionComponent<InstructionEditProps> = (props) => {
  const {
    classes,
    onSubmit,
    onInstructionsDataChange,
    handleInstructionAdd,
    handleInstructionRemove,
    handleInstructionCopy,
    handleTemplateOpen,
    handleCreateGroup,
    handleResetData,
    handleSaveLines,
    handleTogglePIHead,
    showPIHead,
    order,
    instructions,
    producers,
    varieties,
    variety,
    weeks,
    targets,
    classesdata,
    counts,
    coldrooms,
    palletspecs,
    brands,
    inventories,
    mgps,
    costestimations,
    handleSetExchange,
    estimatedcost,
    groupMaster,
  } = props;

  let { headerdirty } = props;

  const producersMapped = producers
    .sort((a, b) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 1;
    })
    .map((producer) => {
      return { value: producer.code, display: `${producer.name}` };
    });

  const varietyCommodity = varieties.find((v) => v.id == order.variety);

  const weeksMapped = weeks
    .sort((a, b) => {
      if (Number(a.week.toString().replace(" ", "")) > Number(b.week.toString().replace(" ", ""))) return -1;
      if (Number(a.week.toString().replace(" ", "")) < Number(b.week.toString().replace(" ", ""))) return 1;
      return 1;
    })
    .map((week) => {
      return { value: week.id, display: week.week };
    });

  const week = weeks.find((wk) => wk.id == order.week);

  let instructionsUpdated = undefined;
  const onInstructionsDataChangeLocal = (instructions) => {
    instructionsUpdated = instructions;
    onInstructionsDataChange(instructions);
  };

  return (
    <div id="instructionEdit">
      <div id="instructionEditinner">
        <Form
          onSubmit={(e) => {
            onSubmit(e, instructionsUpdated);
          }}
          validate={(values) => {
            const validateValuesNoCommodity = { ...values };
            delete validateValuesNoCommodity["commodity"];

            const eq = JSON.stringify(validateValuesNoCommodity) == JSON.stringify(order);
            if (!eq) {
              headerdirty = true;
            }
            const errors = {};
            return errors;
          }}
          initialValues={{ ...order, commodity: varietyCommodity.commodity_id }}
          render={({ handleSubmit, form, values }: any) => (
            <form onSubmit={handleSubmit}>
              <div className={classes.wrapper}>
                <OrderActions
                  handleTemplateOpen={handleTemplateOpen}
                  handleCreateGroup={handleCreateGroup}
                  handleTogglePIHead={handleTogglePIHead}
                  showPIHead={showPIHead}
                  order={order}
                />
                {showPIHead && (
                  <OrderHeader
                    producersMapped={producersMapped}
                    // varietiesMapped={varietiesMapped}
                    variety={varietyCommodity}
                    weeksMapped={weeksMapped}
                    exchange={order.exchange}
                    handleSetExchange={handleSetExchange}
                    groupMaster={groupMaster}
                    orderid={order.id}
                    form={form}
                  />
                )}
                <div id="maingridcontent">
                  {!headerdirty && (
                    <InstructionGrid
                      onInstructionsDataChange={onInstructionsDataChangeLocal}
                      onInstructionsDataChangePush={onInstructionsDataChange}
                      data={instructions}
                      targets={targets.data}
                      counts={counts}
                      classesArr={classesdata}
                      palletspecs={palletspecs}
                      coldrooms={coldrooms}
                      brands={brands}
                      inventories={inventories}
                      mgps={mgps}
                      commission={order.commission}
                      exchange={order.exchange}
                      costestimations={costestimations}
                      local={order.local}
                      fixed={order.fixed}
                      handleCopy={handleInstructionCopy}
                      handleRemove={handleInstructionRemove}
                      handleAdd={handleInstructionAdd}
                      handleSaveLines={handleSaveLines}
                      estimatedcost={estimatedcost}
                      variety={variety}
                      handleResetData={handleResetData}
                      week={week}
                      values={values}
                    />
                  )}
                </div>
              </div>
            </form>
          )}
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(InstructionEdit);
