import React from "react";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

import Close from "@material-ui/icons/Close";
import Checkbox from "@material-ui/core/Checkbox";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";
import { CellActionsFormatter } from "../../lib/helpers/CellActionsFormatter";
import { dynamicWidths } from "../../lib/helpers/DynamicWidth";
import { DateFormatter } from "../../lib/components/Formatters";
import { handleColumnsArrangement, handleColumnsWidth } from "../../lib/helpers/grid";

const CheckBoxEditor = ({ row, column, onRowChange }) => {
  return (
    <div style={{ width: "100%" }}>
      <Checkbox
        key={`solasmethodcheck`}
        checked={row[column.key] == 1 ? true : false}
        onChange={(event) => onRowChange({ ...row, [column.key]: event.target.checked }, true)}
        style={{ padding: "8px", backgroundColor: "lightgray" }}
      />
    </div>
  );
};

const CheckboxFormatter = ({ row, column }) => {
  const data = row[column.key];
  if (data && data == 1) {
    return (
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <CheckIcon style={{ color: "green" }} />
      </div>
    );
  }
  return (
    <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
      <Close style={{ color: "red" }} />
    </div>
  );
};

const cellWidthTiny = 40;
const cellWidthXtraSmall = 70;
const cellWidthSmall = 80;
const cellWidthSmallBig = 140;
const cellWidthMedium = 200;
// const cellWidthLarge = 250;
const cellWidthXtrLarge = 350;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;

export const GridColumns = (data, filters, arrangement, columnsWidth, handleRemove, handleEdit) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const cols = [
    { key: "actions", formatter: null },
    { key: "active", formatter: null },
    { key: "code", formatter: null },
    { key: "name", formatter: null },
    { key: "solasmethod2", formatter: CheckboxFormatter },
    { key: "producer_pucs", formatter: null },
    { key: "producer_packhouses", formatter: null },
    { key: "physical_address", formatter: null },
    { key: "postal_address", formatter: null },
    { key: "producer_telephone", formatter: null },
    { key: "producer_mobile", formatter: null },
    { key: "sales_person", formatter: null },
    { key: "sales_phone", formatter: null },
    { key: "sales_email", formatter: null },
    { key: "quality_person", formatter: null },
    { key: "quality_phone", formatter: null },
    { key: "quality_email", formatter: null },
    { key: "finance_person", formatter: null },
    { key: "finance_phone", formatter: null },
    { key: "finance_email", formatter: null },
    { key: "last_updated_user", formatter: null },
    { key: "updated_at", formatter: DateFormatter },
  ];

  let calculatedWidths = {};

  if ((data || []).length > 0) {
    calculatedWidths = dynamicWidths(data, cols);
  }

  const columns = [
    {
      key: "actions",
      name: "Actions",
      width: cellWidthSmall,
      formatter: ({ row }) => {
        const actions = cellActions(row, handleRemove, handleEdit);
        return (
          <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
            <CellActionsFormatter actions={actions} />
          </div>
        );
      },
    },
    {
      key: "active",
      name: "Active" + postValue,
      width: calculatedWidths["active"] || cellWidthTiny,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"active"} data={data} section={"maintproducers"} />;
      },
      formatter: ({ row }) => (
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>{row.active ? <CheckIcon style={{ color: "green" }} /> : <CloseIcon color="error" />}</div>
      ),
    },
    {
      key: "code",
      name: "Code" + postValue,
      width: 80,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"code"} data={data} section={"maintproducers"} />;
      },
    },
    {
      key: "name",
      name: "Name" + postValue,
      width: calculatedWidths["name"] || cellWidthXtrLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"name"} data={data} section={"maintproducers"} />;
      },
    },
    {
      key: "solasmethod2",
      name: "SM2" + postValue,
      width: cellWidthTiny,
      filterable: false,
      editor: CheckBoxEditor,
      formatter: CheckboxFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"solasmethod2"} data={data} section={"maintproducers"} />;
      },
    },
    {
      key: "producer_pucs",
      name: "PUCS" + postValue,
      width: calculatedWidths["producer_pucs"] || cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producer_pucs"} data={data} section={"maintproducers"} />;
      },
    },
    {
      key: "producer_packhouses",
      name: "PHC" + postValue,
      width: calculatedWidths["producer_packhouses"] || cellWidthSmallBig,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producer_packhouses"} data={data} section={"maintproducers"} />;
      },
    },
    {
      key: "physical_address",
      name: "Address" + postValue,
      width: calculatedWidths["physical_address"] || cellWidthXtrLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"physical_address"} data={data} section={"maintproducers"} />;
      },
    },
    {
      key: "postal_address",
      name: "Postal" + postValue,
      width: calculatedWidths["postal_address"] || cellWidthXtrLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"postal_address"} data={data} section={"maintproducers"} />;
      },
    },
    {
      key: "producer_telephone",
      name: "Telephone" + postValue,
      width: calculatedWidths["producer_telephone"] || cellWidthXtrLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producer_telephone"} data={data} section={"maintproducers"} />;
      },
    },
    {
      key: "producer_mobile",
      name: "Mobile" + postValue,
      width: calculatedWidths["producer_mobile"] || cellWidthXtrLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producer_mobile"} data={data} section={"maintproducers"} />;
      },
    },
    {
      key: "sales_person",
      name: "Sales person" + postValue,
      width: calculatedWidths["sales_person"] || cellWidthXtrLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sales_person"} data={data} section={"maintproducers"} />;
      },
    },
    {
      key: "sales_phone",
      name: "Sales phone" + postValue,
      width: calculatedWidths["sales_phone"] || cellWidthXtrLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sales_phone"} data={data} section={"maintproducers"} />;
      },
    },
    {
      key: "sales_email",
      name: "Sales email" + postValue,
      width: calculatedWidths["sales_email"] || cellWidthXtrLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sales_email"} data={data} section={"maintproducers"} />;
      },
    },
    {
      key: "quality_person",
      name: "Quality person" + postValue,
      width: calculatedWidths["quality_person"] || cellWidthXtrLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"quality_person"} data={data} section={"maintproducers"} />;
      },
    },
    {
      key: "quality_phone",
      name: "Quality phone" + postValue,
      width: calculatedWidths["quality_phone"] || cellWidthXtrLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"quality_phone"} data={data} section={"maintproducers"} />;
      },
    },
    {
      key: "quality_email",
      name: "Quality email" + postValue,
      width: calculatedWidths["quality_email"] || cellWidthXtrLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"quality_email"} data={data} section={"maintproducers"} />;
      },
    },
    {
      key: "finance_person",
      name: "Finance person" + postValue,
      width: calculatedWidths["finance_person"] || cellWidthXtrLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"finance_person"} data={data} section={"maintproducers"} />;
      },
    },
    {
      key: "finance_phone",
      name: "Finance phone" + postValue,
      width: calculatedWidths["finance_phone"] || cellWidthXtrLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"finance_phone"} data={data} section={"maintproducers"} />;
      },
    },
    {
      key: "finance_email",
      name: "Finance email" + postValue,
      width: calculatedWidths["finance_email"] || cellWidthXtrLarge,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"finance_email"} data={data} section={"maintproducers"} />;
      },
    },
    {
      key: "last_updated_user",
      name: "User Last Updated" + postValue,
      width: calculatedWidths["last_updated_user"] || cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"last_updated_user"} data={data} section={"maintproducers"} />;
      },
    },
    {
      key: "updated_at",
      name: "Last Updated" + postValue,
      width: calculatedWidths["updated_at"] || cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"updated_at"} data={data} section={"maintproducers"} />;
      },
      formatter: DateFormatter,
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);
  return columnsArranged;
};

const cellActions = (order, handleRemove, handleEdit) => {
  return [
    {
      icon: (
        <span title="Edit">
          <EditIcon style={{ cursor: "pointer", color: "orange" }} />
        </span>
      ),
      callback: (e) => {
        handleEdit(order.id);
      },
    },
    {
      icon: (
        <span title="Remove order">
          <DeleteIcon style={{ cursor: "pointer", color: "red" }} />
        </span>
      ),
      callback: (e) => {
        handleRemove(order.id);
      },
    },
  ];
};
