import React from "react";

import CustomAutoCompleteFilter from "../lib/components/customdatagridfilter/CustomFilter";
import { CellActionsFormatter } from "../lib/helpers/CellActionsFormatter";
import IconWarning from "@material-ui/icons/Warning";
import IconInfo from "@material-ui/icons/Info";
import IconLock from "@material-ui/icons/Lock";
import IconCheck from "@material-ui/icons/Check";

import { DateFormatter, FormatterNumber, FormatterNumber2Decimals } from "../lib/components/Formatters";
import TextEditor from "../lib/components/TextEditor";
import { InvoiceLinesStatus } from "../lib/api/invoiceItemsAudit";
import { SummaryNumber, SummaryNumber2Decimal } from "../lib/components/SummaryFormatter";
import { handleColumnsArrangement, handleColumnsWidth } from "../lib/helpers/grid";

const cellWidthAction = 50;
const cellWidthTiny = 40;
const cellWidthSmallX = 50;
const cellWidthSmall = 80;
const cellWidthMedium = 120;
const cellWidthLarge = 200;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;

export const GridColumns = (data, filters, arrangement, columnsWidth, handleAudit, handleInfo) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "invoiceNumber",
      name: "Invoice" + postValue,
      width: cellWidthSmallX,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"invoiceNumber"} data={data} section="integrationinvoicelines" />;
      },
    },
    {
      key: "intakeDate",
      name: "Intake Date" + postValue,
      width: cellWidthSmallX,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"intakeDate"} data={data} section="integrationinvoicelines" />;
      },
      formatter: DateFormatter,
    },
    {
      key: "week",
      name: "Cold Week" + postValue,
      width: cellWidthSmallX,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"week"} data={data} section="integrationinvoicelines" />;
      },
    },
    {
      key: "producerId",
      name: "Producer ID" + postValue,
      width: cellWidthSmallX,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producerId"} data={data} section="integrationinvoicelines" />;
      },
    },
    {
      key: "intakeWaybill",
      name: "Orig Waybill Doc" + postValue,
      width: cellWidthSmallX,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"intakeWaybill"} data={data} section="integrationinvoicelines" />;
      },
    },
    {
      key: "barcode",
      name: "Barcode" + postValue,
      width: cellWidthSmallX,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode"} data={data} section="integrationinvoicelines" />;
      },
      summaryFormatter: ({ row, column }) => <SummaryNumber row={row} column={{ ...column, key: "noBarcodes" }} />,
    },
    {
      key: "commodity",
      name: "Commodity" + postValue,
      width: cellWidthSmallX,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"commodity"} data={data} section="integrationinvoicelines" />;
      },
    },
    {
      key: "varietyCode",
      name: "Variety" + postValue,
      width: cellWidthSmallX,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"varietyCode"} data={data} section="integrationinvoicelines" />;
      },
    },
    {
      key: "gradeCode",
      name: "Grade" + postValue,
      width: cellWidthSmallX,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"gradeCode"} data={data} section="integrationinvoicelines" />;
      },
    },
    {
      key: "packCode",
      name: "Pack" + postValue,
      width: cellWidthSmallX,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"packCode"} data={data} section="integrationinvoicelines" />;
      },
    },
    {
      key: "countCode",
      name: "Count" + postValue,
      width: cellWidthSmallX,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"countCode"} data={data} section="integrationinvoicelines" />;
      },
    },
    {
      key: "markCode",
      name: "Mark" + postValue,
      width: cellWidthSmallX,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"markCode"} data={data} section="integrationinvoicelines" />;
      },
    },
    {
      key: "inventoryCode",
      name: "Inv" + postValue,
      width: cellWidthSmallX,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"inventoryCode"} data={data} section="integrationinvoicelines" />;
      },
    },
    {
      key: "noCartons",
      name: "No Cartons" + postValue,
      width: cellWidthSmallX,
      filterable: true,
      formatter: (props) => <FormatterNumber {...props} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"noCartons"} data={data} section="integrationinvoicelines" />;
      },
      summaryFormatter: SummaryNumber,
    },
    {
      key: "palletSize",
      name: "Pallet Size" + postValue,
      width: cellWidthSmallX,
      filterable: true,
      formatter: (props) => <FormatterNumber2Decimals {...props} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"palletSize"} data={data} section="integrationinvoicelines" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "clientsName",
      name: "Client" + postValue,
      width: cellWidthSmallX,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"clientsName"} data={data} section="integrationinvoicelines" />;
      },
    },
    {
      key: "consigneeId",
      name: "Consignee" + postValue,
      width: cellWidthSmallX,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"consigneeId"} data={data} section="integrationinvoicelines" />;
      },
    },
    {
      key: "POD",
      name: "POD" + postValue,
      width: cellWidthSmallX,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"POD"} data={data} section="integrationinvoicelines" />;
      },
    },
    {
      key: "destination",
      name: "Dest" + postValue,
      width: cellWidthSmallX,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"destination"} data={data} section="integrationinvoicelines" />;
      },
    },
    {
      key: "targetMarket",
      name: "TM" + postValue,
      width: cellWidthSmallX,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"targetMarket"} data={data} section="integrationinvoicelines" />;
      },
    },
    {
      key: "targetCountry",
      name: "TC" + postValue,
      width: cellWidthSmallX,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"targetCountry"} data={data} section="integrationinvoicelines" />;
      },
    },
    {
      key: "shippingMarket",
      name: "SM" + postValue,
      width: cellWidthSmallX,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"shippingMarket"} data={data} section="integrationinvoicelines" />;
      },
    },
    {
      key: "fob",
      name: "PI FOB" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: (props) => {
        let backgroundColorSwitch = "white";

        if (props.row && props.row.match && props.row.match.includes("1 -")) {
          backgroundColorSwitch = "lightGreen";
        }
        if (props.row && props.row.match && props.row.match.includes("2 -")) {
          backgroundColorSwitch = "orange";
        }
        if (props.row && props.row.match && props.row.match.includes("3 -")) {
          backgroundColorSwitch = "pink";
        }
        return <FormatterNumber2Decimals {...props} style={{ backgroundColor: backgroundColorSwitch, fontWeight: "bold" }} />;
      },
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"fob"} data={data} section="integrationinvoicelines" />;
      },
      editor: (props) => <TextEditor {...props} align="right" />,
      editable: (row) => (row.accepted == 1 ? false : true),
    },
    {
      key: "dip",
      name: "PI DIP" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: (props) => {
        let backgroundColorSwitch = "white";

        if (props.row && props.row.match && props.row.match.includes("1 -")) {
          backgroundColorSwitch = "lightGreen";
        }
        if (props.row && props.row.match && props.row.match.includes("2 -")) {
          backgroundColorSwitch = "orange";
        }
        if (props.row && props.row.match && props.row.match.includes("3 -")) {
          backgroundColorSwitch = "pink";
        }
        return <FormatterNumber2Decimals {...props} style={{ backgroundColor: backgroundColorSwitch, fontWeight: "bold" }} />;
      },
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"dip"} data={data} section="integrationinvoicelines" />;
      },
      editor: (props) => <TextEditor {...props} align="right" />,
      editable: (row) => (row.accepted == 1 ? false : true),
    },
    {
      key: "actions",
      name: "Actions",
      width: cellWidthTiny,
      formatter: ({ row }) => {
        const actions = cellActions(row, handleAudit, handleInfo);
        return (
          <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
            <CellActionsFormatter actions={actions} />
          </div>
        );
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);
  return columnsArranged;
};

const cellActions = (row, handleAudit, handleInfo) => {
  const actionsArr = [];

  if (row.accepted == InvoiceLinesStatus.Accepted || row.merged == InvoiceLinesStatus.Merged) {
    actionsArr.push({
      icon: (
        <span title="Info">
          <IconLock style={{ cursor: "pointer", color: "grey" }} />
        </span>
      ),
      callback: (e) => {
        console.log("do nothing");
      },
    });
  }

  actionsArr.push(
    {
      icon: (
        <span title="Info">
          <IconInfo style={{ cursor: "pointer", color: "blue" }} />
        </span>
      ),
      callback: (e) => {
        handleInfo(row);
      },
    },
    {
      icon: row.accepted ? <IconCheck cursor={"pointer"} style={{ color: "green" }} /> : <IconWarning cursor={"pointer"} style={{ color: "orange" }} />,
      callback: (e) => {
        handleAudit([row]);
      },
    },
  );

  return actionsArr;
};
