// import { config } from "../../config";
import { client, apiHeaders, request } from "./_base";

interface IOrder {
  id: number;
  ordernum: string;
  variety: number;
  farm: number;
  week: number;
  exchange: string;
  locked: boolean;
  revision: number;
  printdate: Date;
  exworks: boolean;
  comment: Text;
  impala_comments: Text;
  commission: number;
  local: boolean;
  groupnumber: string;
  groupsequence: number;
}

export type OrdersFull = {
  comment: string;
  commodityConcatenated: string;
  exchange: string;
  exworks: number;
  farm: string;
  fixed: number;
  groupnumber: string;
  groupnumberindicator: string;
  groupsequence: number;
  id: number;
  local: number;
  locked: number;
  ordernum: string;
  period_start: string;
  printdate: string;
  producer: string;
  revision: number;
  variety: string;
  varietyCode: string;
  varietyConcatenated: string;
  variety_id: string;
  week: string;
  week_id: number;
};

export const piorder = client("piorder", (item) => ({
  ...item,
}));

export const getOrderById = async (id: number) => {
  const result = await request(`piorder/ext/getOrderById/${id}`, {
    headers: apiHeaders(),
    method: "GET",
  });
  const resp = await result.json();

  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const piorderFull = (financialyear = "0") =>
  request(`piorder/extend/all/${financialyear}`, {
    headers: apiHeaders(),
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });

export const removeWithInstructions = (id) =>
  request(`piorder/removeWithIns/${id}`, {
    headers: apiHeaders(),
    method: "DELETE",
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });

export const getOrders = (idList) =>
  request(`piorder/getOrders/${idList}`, {
    headers: apiHeaders(),
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });

export const getNextAvailableOrderNumber = (ordernumber) =>
  request(`piorder/getNextAvailableOrderNumber/${ordernumber}`, {
    headers: apiHeaders(),
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });

export const getOrdersByGroupNumber = (groupnumber) =>
  request(`piorder/ext/getOrdersByGroupNumber/${groupnumber}`, {
    headers: apiHeaders(),
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });

export const getOrderDetailById = (orderId: number) =>
  request(`piorder/ext/orderHeaderDetailById/${orderId}`, {
    headers: apiHeaders(),
  }).then(async (result) => {
    if (result.status == 500) {
      const resp = await result.json();
      throw resp;
    }
    return result.json();
  });

export const copyOrderByOrderId = async (orderData: IOrder, copyId: number): Promise<any> => {
  const result = await request(`piorder/ext/copyOrderByOrderId`, {
    method: "POST",
    headers: apiHeaders(),
    body: JSON.stringify({ order: orderData, copyId }),
  });
  const resp = await result.json();
  if (result.status === 500) {
    throw resp;
  }
  return resp.data;
};
