import { client, request, apiHeaders } from "./_base";

const endpoint = "port";

export type Port = {
  id: number;
  description: string;
  code: string;
  target_id: number;
  region: string;
  market: string;
  country: string;
  loading: 0 | 1;
};

export const port = client(endpoint, (item) => ({
  ...item,
}));

export const getPortFull = async (country = null, showActive: boolean = null): Promise<Port[]> => {
  const result = await request(`${endpoint}/ext/full?country=${country}&showActive=${showActive}`, {
    headers: apiHeaders(),
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const getPortReadyForCombo = async (country = null) => {
  const result = await getPortFull(country, true);
  return result
    .sort((a, b) => a.description.localeCompare(b.description))
    .map((port) => ({ value: port.id, display: port.description + " - " + port.code + " - " + port.region, data: port }));
};

export type PortReadyForComboIdValue = {
  id: number;
  display: string;
  code: string;
  data: Port;
  description: string;
};

export const getPortReadyForComboIdValue = async (country = null): Promise<PortReadyForComboIdValue[]> => {
  const result = await getPortFull(country, true);
  return result
    .sort((a, b) => a.description.localeCompare(b.description))
    .map((port) => ({ id: port.id, description: port.description, display: port.description + " - " + port.code + " - " + port.region, code: port.code, data: port }));
};

export const getPortReadyForComboWithSearch = async (country = null) => {
  const result = await getPortFull(country, true);
  return result
    .sort((a, b) => a.description.localeCompare(b.description))
    .map((port) => ({ value: port.id, display: `${port.code} - ${port.description} - ${port.region}`, search: port.code }));
};

export const getPortsLoading = async (showActive: boolean = null): Promise<Port[]> => {
  const result = await request(`${endpoint}/ext/portsLoading?showActive=${showActive}`, {
    headers: apiHeaders(),
  });
  const resp = await result.json();
  if (result.status == 500) {
    throw resp;
  }
  return resp.data;
};

export const getPortsLoadingForCombo = async () => {
  const result = await getPortsLoading(true);
  return result.sort((a, b) => a.description.localeCompare(b.description)).map((port) => ({ value: port.id, display: port.description, data: port }));
};
