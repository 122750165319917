import React from "react";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import { GridColumns } from "./ordersGridSetup";
import Grid from "../lib/components/grid";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    rowNormal: {},
    rowDragging: { borderStyle: "solid", borderWidth: "1px", borderColor: "black" },
    rowOver: {
      backgroundColor: "white",
    },
    rowOdd: {
      backgroundColor: "aliceblue",
    },
    rowUnderScore: {
      borderWidth: "1px 0px 0px 0px",
      borderStyle: "solid",
    },
    orderButtons: {
      marginLeft: "10px",
      marginBottom: "10px",
    },
    inline: {
      display: "inline",
    },
  });

class OrdersGrid extends React.Component<any, any> {
  state = {
    orders: undefined,
    handleEdit: undefined,
    handleCopy: undefined,
    handleRemove: undefined,
    handleRevision: undefined,
    handleLock: undefined,
    handleCreate: undefined,
    handleExport: undefined,
    page: 0,
    rowsPerPage: 15,
    classes: undefined,
    tabValue: 0,
    previousYearOrders: [],
    minGridHeight: 800,
  };

  constructor(props) {
    super(props);
    this.state.handleEdit = props.handleEdit;
    this.state.handleRemove = props.handleRemove;
    this.state.handleCopy = props.handleCopy;
    this.state.handleRevision = props.handleRevision;
    this.state.handleLock = props.handleLock;
    this.state.handleCreate = props.handleCreate;
    this.state.handleExport = props.handleExport;
    this.state.orders = props.orders;
    this.state.classes = props.classes;
    this.state.previousYearOrders = props.previousYearOrders;
  }

  componentDidMount() {
    this.setGridHeights();

    window.addEventListener("resize", () => {
      this.setGridHeights();
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", () => {});
  }

  setGridHeights = () => {
    const parent = document.getElementById("ordersgridmain");
    this.setState({ minGridHeight: parent.clientHeight - 150 });
  };

  handleChangeTab = (event, tabValue) => {
    this.setState({ tabValue: tabValue });
  };

  GridColumnsOverride = (data, filters, columnArrangement, columnsWidth, section) => {
    return GridColumns(
      data,
      filters,
      columnArrangement,
      columnsWidth,
      this.state.handleRevision,
      this.state.handleCopy,
      this.state.handleLock,
      this.state.handleRemove,
      this.state.handleEdit,
      section,
    );
  };

  render() {
    return (
      <div id="ordersgridmain" style={{ height: "calc(100dvh - 100px)", paddingTop: "10px" }}>
        <Paper style={{ position: "relative" }}>
          <AppBar position="static">
            <Tabs value={this.state.tabValue} onChange={this.handleChangeTab} aria-label="orders-tabs">
              <Tab label="Current Year" {...a11yProps(0)} />
              <Tab label="Previous Year" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel value={this.state.tabValue} index={0}>
            <Grid
              clearFilters={"orders"}
              data={this.state.orders}
              forceHeight={this.state.minGridHeight}
              handleExport={() => this.state.handleExport(this.state.tabValue)}
              GridColumns={(data, filters, columnArrangement, columnsWidth) => this.GridColumnsOverride(data, filters, columnArrangement, columnsWidth, "orders")}
              toolbarChildren={
                <Tooltip title="Create new Order">
                  <Button variant="contained" color="primary" onClick={this.state.handleCreate} style={{ float: "left", margin: "15px 5px" }}>
                    create order
                  </Button>
                </Tooltip>
              }
            />
          </TabPanel>
          <TabPanel value={this.state.tabValue} index={1}>
            <Grid
              clearFilters={"previousOrders"}
              data={this.state.previousYearOrders}
              forceHeight={this.state.minGridHeight}
              handleExport={() => this.state.handleExport(this.state.tabValue)}
              GridColumns={(data, filters, columnArrangement, columnsWidth) => this.GridColumnsOverride(data, filters, columnArrangement, columnsWidth, "previousOrders")}
              toolbarChildren={
                <Tooltip title="Create new Order">
                  <Button variant="contained" color="primary" onClick={this.state.handleCreate} style={{ float: "left", margin: "15px 5px" }}>
                    create order
                  </Button>
                </Tooltip>
              }
            />
          </TabPanel>
        </Paper>
      </div>
    );
  }
}

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`orders-tabs-${index}`} aria-labelledby={`orders-tab-${index}`} {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `orders-tab-${index}`,
    "aria-controls": `orders-tabs-${index}`,
  };
};

export default withStyles(styles)(OrdersGrid);
