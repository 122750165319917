import { apiHeaders, client, request } from "./_base";

export const users = client("users", (item) => ({
  ...item,
}));
const endpoint = "users";

// TODO: move permissions to its own table
export type User = {
  active: boolean;
  admin: boolean;
  can_adjust: boolean;
  consolidation_lock_permission: boolean;
  created_at: string;
  email: string;
  estimatedcost_edit: boolean;
  id: number;
  password: string;
  stockalloc_permission: boolean;
  super: boolean;
  updated_at: string;
  username: string;
};

export const getUsersAllSortedMappedforCombo = async (onlyActive: boolean) => {
  const result = await users.all().then((data) => {
    return data
      .filter((item) => (item.active == onlyActive ? 1 : 0))
      .sort((a, b) => {
        if (a.username.toLowerCase() > b.username.toLowerCase()) return 1;
        if (a.username.toLowerCase() < b.username.toLowerCase()) return -1;
        return 1;
      })
      .map((item) => {
        return { value: item.id, display: `${item.username}` };
      });
  });
  return result;
};

export const resetPassword = async (user: User, password: string) => {
  const updatedUser = {
    ...user,
    password,
  };

  const content = JSON.stringify(updatedUser);

  const result = await request(`${endpoint}/ex/resetPassword`, {
    method: "PUT",
    headers: apiHeaders(content),
    body: content,
  });

  if (result.status == 500) {
    const resp = await result.json();
    throw resp;
  }

  return result.json();
};
